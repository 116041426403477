import React from 'react';
import { Container, Row, Col, Panel } from 'rsuite';
import './App.css'; // Assuming you have your CSS in this file

// Sample product data
const products = [
  {
    id: 1,
    title: 'very long product',
    image: './photo.jpg',
    icons: ['👍', '❤️']
  },
  {
    id: 2,
    title: 'Product 2',
    image: './photo.jpg',
    icons: ['⭐', '🛒']
  },
  {
    id: 3,
    title: 'Product 3',
    image: './photo.jpg',
    icons: ['🔔', '💬']
  },
  {
    id: 1,
    title: 'very long product',
    image: './photo.jpg',
    icons: ['👍', '❤️']
  },
  {
    id: 2,
    title: 'Product 2',
    image: './photo.jpg',
    icons: ['⭐', '🛒']
  },
  {
    id: 3,
    title: 'Product 3',
    image: './photo.jpg',
    icons: ['🔔', '💬']
  },
  {
    id: 1,
    title: 'very long product',
    image: './photo.jpg',
    icons: ['👍', '❤️']
  },
  {
    id: 2,
    title: 'Product 2',
    image: './photo.jpg',
    icons: ['⭐', '🛒']
  },
  {
    id: 3,
    title: 'Product 3',
    image: './photo.jpg',
    icons: ['🔔', '💬']
  },
  {
    id: 1,
    title: 'very long product',
    image: './photo.jpg',
    icons: ['👍', '❤️']
  },
  {
    id: 2,
    title: 'Product 2',
    image: './photo.jpg',
    icons: ['⭐', '🛒']
  },
  {
    id: 3,
    title: 'Product 3',
    image: './photo.jpg',
    icons: ['🔔', '💬']
  },
  {
    id: 1,
    title: 'very long product',
    image: './photo.jpg',
    icons: ['👍', '❤️']
  },
  {
    id: 2,
    title: 'Product 2',
    image: './photo.jpg',
    icons: ['⭐', '🛒']
  },
  {
    id: 3,
    title: 'Product 3',
    image: './photo.jpg',
    icons: ['🔔', '💬']
  },
  {
    id: 1,
    title: 'very long product',
    image: './photo.jpg',
    icons: ['👍', '❤️']
  },
  {
    id: 2,
    title: 'Product 2',
    image: './photo.jpg',
    icons: ['⭐', '🛒']
  },
  {
    id: 3,
    title: 'Product 3',
    image: './photo.jpg',
    icons: ['🔔', '💬']
  },
  // Add more products as needed
];

const Products = () => {
  return (
    <Container>
      <Row>
        {products.map(product => (
          <Col style={{ minHeight:'15%', maxHeight:'15%'}} xs={12} sm={8} lg={6} md={8} key={product.id}>
            <Panel shaded bordered style={{ margin: '10px', flexGrow:0 }}>
              <img className="card-image" src={product.image} alt={product.title} />
              <Panel header={product.title} style={{ textAlign: 'center', maxHeight:'110px', minHeight:'110px', overflowY:'hidden' }}>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {product.icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                  ))}
                </div>
              </Panel>
            </Panel>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Products;