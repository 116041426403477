import './App.css';
import Products from './Products';

function App() {
  return (
    <div className="App">
     <Products />
    </div>
  );
}

export default App;
